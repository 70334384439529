import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
<path d="M1237 3572 c-15 -17 -17 -54 -17 -410 0 -342 2 -392 15 -392 8 0 15
-4 15 -10 0 -7 423 -10 1250 -10 827 0 1250 3 1250 10 0 6 14 10 30 10 l30 0
0 168 c0 189 -13 271 -60 369 -39 84 -133 178 -220 220 -118 57 -166 63 -524
63 -302 0 -324 -1 -340 -19 -15 -16 -16 -56 -16 -407 l1 -389 -182 -3 c-171
-2 -181 -1 -173 15 5 10 10 93 12 184 3 130 0 180 -13 230 -43 168 -154 290
-322 357 l-68 26 -325 4 c-310 3 -326 2 -343 -16z m599 -378 c67 -34 84 -83
84 -237 0 -95 -4 -136 -15 -157 l-16 -30 -139 0 -140 0 0 218 c0 120 3 221 6
225 4 3 46 5 94 3 63 -2 98 -8 126 -22z m1459 6 c85 -33 105 -83 105 -277 l0
-153 -165 0 -165 0 0 218 c0 120 3 221 6 225 10 9 187 0 219 -13z"/>
<path d="M1500 2490 l0 -80 28 0 c44 0 51 11 51 75 -1 69 -10 85 -50 85 l-29
0 0 -80z m54 41 c3 -5 -1 -14 -9 -21 -13 -10 -15 -9 -15 9 0 21 14 28 24 12z
m-1 -57 c8 -8 -3 -34 -14 -34 -5 0 -9 9 -9 20 0 19 11 26 23 14z"/>
<path d="M1736 2554 c-22 -22 -23 -144 -1 -144 8 0 15 9 15 20 0 11 7 20 15
20 9 0 15 -9 15 -21 0 -12 5 -19 13 -17 19 7 21 123 1 142 -20 20 -38 20 -58
0z m42 -47 c2 -19 -1 -27 -12 -27 -11 0 -16 10 -16 31 0 38 24 35 28 -4z"/>
<path d="M1962 2558 c-16 -16 -17 -151 -1 -146 7 2 12 28 13 63 1 37 6 60 14
62 9 4 12 -13 12 -61 0 -52 3 -66 15 -66 12 0 15 14 15 70 0 58 -3 71 -19 80
-25 13 -34 13 -49 -2z"/>
<path d="M2166 2555 c4 -8 10 -15 15 -15 5 0 9 -29 9 -65 0 -51 3 -65 15 -65
12 0 15 14 15 64 0 49 4 65 16 70 26 10 5 26 -36 26 -31 0 -38 -3 -34 -15z"/>
<path d="M2380 2531 c0 -22 5 -43 10 -46 6 -3 10 -22 10 -41 0 -24 5 -34 15
-34 10 0 15 10 15 29 0 16 7 34 15 41 9 7 15 29 15 51 0 28 -4 39 -15 39 -11
0 -15 -11 -15 -36 0 -24 -4 -34 -12 -31 -12 4 -14 9 -17 50 -2 35 -21 16 -21
-22z"/>
<path d="M2780 2490 l0 -80 28 0 c41 0 52 17 52 80 0 63 -11 80 -52 80 l-28 0
0 -80z m50 0 c0 -27 -4 -50 -10 -50 -5 0 -10 23 -10 50 0 28 5 50 10 50 6 0
10 -22 10 -50z"/>
<path d="M3016 2554 c-22 -22 -23 -144 -1 -144 8 0 15 9 15 20 0 11 7 20 15
20 9 0 15 -9 15 -21 0 -12 5 -19 13 -17 19 7 21 123 1 142 -20 20 -38 20 -58
0z m42 -47 c2 -19 -1 -27 -12 -27 -11 0 -16 10 -16 31 0 38 24 35 28 -4z"/>
<path d="M3220 2555 c0 -8 7 -15 15 -15 12 0 15 -14 15 -65 0 -47 4 -65 13
-65 10 0 12 15 9 60 -4 48 -1 61 12 66 33 12 18 34 -24 34 -29 0 -40 -4 -40
-15z"/>
<path d="M3445 2554 c-20 -20 -18 -135 3 -142 7 -2 12 5 12 17 0 12 6 21 15
21 8 0 15 -9 15 -20 0 -11 7 -20 15 -20 12 0 15 14 15 70 0 58 -3 71 -19 80
-27 14 -37 13 -56 -6z m45 -44 c0 -20 -5 -30 -15 -30 -15 0 -21 40 -8 53 14
15 23 6 23 -23z"/>
<path d="M1238 2231 c-17 -16 -18 -48 -18 -383 0 -341 1 -366 18 -381 16 -15
54 -17 332 -17 255 0 326 3 379 16 188 46 342 195 386 373 13 53 16 104 13
230 l-3 162 153 -3 153 -3 -1 -365 c0 -317 2 -368 15 -387 l16 -23 324 0 c359
0 407 6 525 63 87 42 181 136 220 220 45 95 60 181 60 350 l0 147 -30 0 c-16
0 -30 5 -30 10 0 7 -422 10 -1247 9 -1181 0 -1249 -1 -1265 -18z m697 -141 c0
-154 -11 -191 -73 -238 -32 -25 -44 -27 -143 -30 l-108 -4 -3 206 -3 206 165
-2 165 -3 0 -135z m1465 10 c0 -78 -5 -140 -12 -157 -41 -95 -91 -122 -225
-123 l-93 0 0 205 0 205 165 0 165 0 0 -130z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
